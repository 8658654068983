<template>
  <div
    class="text-center d-flex justify-center align-self-center flex-column py-16"
  >
    <v-progress-circular
      indeterminate
      size="100"
      color="primary"
      class="mb-6 mx-auto"
    ></v-progress-circular>
    <br />
    <span>Carregando...</span>
  </div>
</template>

<script>
import { getUserMeusDados } from "@/services/user";
import { mapActions } from "vuex";
export default {
  name: "LoginSSO",
  created() {
    this.checkToken();
  },
  methods: {
    ...mapActions(["setUserData", "setUserLoggedIn"]),
    async checkToken() {
      if (window.localStorage.token) {
        window.localStorage.token = `Bearer ${window.localStorage.token}`;
        this.setUserLoggedIn(true);
        const response = await getUserMeusDados();
        this.setUserData(response.data);
        this.$router.push({ path: "/" });
      }
    },
  },
};
</script>
